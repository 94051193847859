import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { StoreContext } from 'pages/_app';
import { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getToBeExcludedDates } from 'utils/helpers/dateTime';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { ArrowDown, CalendarIcon } from 'assets/img';

const DPicker = ({
  selectedDate,
  dateFormat = 'MMMM d, yyyy',
  className,
  pickerClassName,
  minDate,
  activeRestaurant,
  availableDates,
  getAvailableDates,
  getAvailableTimes,
  fromFilters,
  fromModal,
  internalAddress,
  setSelectedDate,
  isGroupOrder,
  isCart,
  isCheckout,
  isModal,
  isInternalSelection,
  backgroundColor,
  placeholder = null,
  grayBackground = true,
  placeholderColor,
}) => {
  const store = useContext(StoreContext);
  const [opened, setOpened] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const router = useRouter();
  const baseClassNames =
    'rounded-md pl-3 py-1 cursor-pointer text-primary-black text-left text-md focus:outline-none';
  const conditionalClasses = [
    pickerClassName ? '' : 'font-inter-medium',
    backgroundColor ? 'input-placeholder font-inter-medium' : 'bg-background',
    placeholderColor ? 'input-placeholder font-inter-medium bg-background' : '',
  ]
    .filter(Boolean)
    .join(' ');

  const datePickerClass = `${baseClassNames} ${conditionalClasses}`;

  return (
    <div
      className={classNames(className, { 'cursor-pointer pl-2 relative': true })}
      onClick={() => setOpened(true)}>
      <Image src={CalendarIcon} alt="calendar-icon" width={15} height={15} />

      {!isCart ? (
        <DatePicker
          open={opened}
          forceShowMonthNavigation={true}
          includeDates={availableDates?.map(date => moment(date).toDate())}
          onMonthChange={date => {
            if (fromModal) {
              store?.userStore?.getAvailableDates({
                address: internalAddress,
                month: moment(date).month() + 1,
                year: moment(date).year(),
                current_date_time: moment().format('YYYY-MM-DD HH:mm'),
              });
            } else {
              getAvailableDates({
                month: moment(date).month() + 1,
                year: moment(date).year(),
              });
            }
          }}
          onCalendarOpen={() => {
            if (selectedDate)
              store?.userStore?.getAvailableDates({
                address: internalAddress,
                month: moment(selectedDate).month() + 1,
                year: moment(selectedDate).year(),
                current_date_time: moment().format('YYYY-MM-DD HH:mm'),
              });
          }}
          placeholderText={placeholder ?? 'Select Date'}
          selected={selectedDate ? new Date(moment(selectedDate)) : ''}
          dateFormat={dateFormat}
          onFocus={e => e.target.blur()}
          minDate={minDate}
          onClickOutside={() => setOpened(false)}
          onChange={date => {
            setTimeout(() => {
              setOpened(false);
            }, 20);

            if (fromModal) {
              store?.userStore?.getAvailableTimes({
                address: internalAddress,
                selected_date: moment(date).format('YYYY-MM-DD'),
                current_date_time: moment().format('YYYY-MM-DD HH:mm'),
              });
            } else if (!fromFilters)
              getAvailableTimes({
                selected_date: moment(date).format('YYYY-MM-DD'),
              });

            if (!fromModal) {
              router.replace(
                {
                  pathname: router.pathname,
                  query: {
                    ...router.query,
                    date: moment(date).format('YYYY-MM-DD'),
                  },
                },
                undefined,
                {
                  shallow: true,
                }
              );
            }

            store.userStore.setDate(date);
          }}
          className={datePickerClass}
          formatWeekDay={nameOfDay =>
            nameOfDay == 'Thursday' ? nameOfDay.slice(0, 2) : nameOfDay.charAt(0)
          }
        />
      ) : (
        <DatePicker
          open={opened}
          onMonthChange={date => setCurrentMonth(date)}
          excludeDates={getToBeExcludedDates(activeRestaurant, currentMonth || selectedDate)}
          placeholderText="Select Date"
          selected={
            selectedDate ? moment(selectedDate).isValid() && new Date(moment(selectedDate)) : ''
          }
          dateFormat={dateFormat}
          onFocus={e => e.target.blur()}
          minDate={minDate}
          onClickOutside={() => setOpened(false)}
          onChange={date => {
            setTimeout(() => {
              setOpened(false);
            }, 20);

            const formattedDate = moment(date).format('YYYY-MM-DD');

            if (isGroupOrder || isInternalSelection) setSelectedDate(formattedDate);
            else {
              store?.cartStore?.saveCartDetails({ cartDate: formattedDate });

              if (!isCheckout && !isModal)
                router.replace(
                  {
                    pathname: router.pathname,
                    query: {
                      ...router.query,
                      date: formattedDate,
                    },
                  },
                  undefined,
                  {
                    shallow: true,
                  }
                );
            }
            store.userStore.setDate(date);
          }}
          className={datePickerClass}
          formatWeekDay={nameOfDay =>
            nameOfDay === 'Thursday' ? nameOfDay.slice(0, 2) : nameOfDay.charAt(0)
          }
        />
      )}

      <div className="absolute right-3 h-full flex items-center">
        <span className="flex justify-end items-center">
          <Image src={ArrowDown} alt="arrow-down" width="17px" height="22px" />
        </span>
      </div>
    </div>
  );
};

export default observer(DPicker);
