import React, { Fragment, useContext, useEffect, useReducer, useState } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { Dialog, Transition } from '@headlessui/react';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import QueryString from 'qs';
import { formattedAddres, parsedAddress } from 'utils/helpers/addresses';
import AddressInput from 'components/Shared/AddressInput';

function DuplicateAddressConfirmation({ visible, setModal, addresses }) {
  const screenType = Screen.ScreenType();
  const store = useContext(StoreContext);
  const cartStore = toJS(store?.cartStore);
  let { address, selectedDate, selectedTime, activeEmployees } = store?.userStore;

  let router = useRouter();

  const initialState = {
    email: '',
    password: '',
    showPassword: false,
    error: '',
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  let { showPassword, email, password, error } = provider;

  const [enableContinue, setEnableContinue] = useState(true);

  const onContinuePress = e => {
    e.preventDefault();

    if (router.pathname !== '/checkout/[slug]')
      router.replace(
        {
          pathname: router?.pathname,
          query: {
            ...QueryString.parse(router?.query),
            address: address,
          },
        },
        undefined,
        {
          shallow: true,
        }
      );

    setModal(false);
  };

  const submitButtons = (
    <div className="flex flex-col">
      <button
        type="submit"
        className={classNames({
          'btn-purple mt-5 h-12': true,
          'bg-gray-400': enableContinue,
          'hover:bg-gray-400': enableContinue,
        })}
        disabled={enableContinue}>
        Continue
      </button>
    </div>
  );

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <form onSubmit={onContinuePress}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed top-0 right-0 left-0 sm:inset-0 overflow-y-auto">
            <div className="flex min-h-screen sm:min-h-full sm:items-center sm:justify-center sm:p-4 md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="flex flex-col flex-1 md:flex-initial w-4/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white py-10 px-8 text-left align-middle shadow-xl transition-all">
                  <div className="flex w-full justify-start items-start">
                    <Dialog.Title
                      as="h3"
                      style={{
                        fontSize: screenType?.isTabletOrMobile ? 23 : 32,
                      }}
                      className="font-inter-semibold leading-6 text-black-light">
                      <span className="text-[24px]">Please select your delivery address. </span>
                    </Dialog.Title>
                  </div>

                  <div className="flex flex-col justify-center items-center flex-wrap mt-10 md:w-full">
                    <div className="flex flex-col w-full">
                      {Array.isArray(addresses) &&
                        addresses?.map((adress, index) => {
                          const formattedAddress = formattedAddres(adress?.attributes);

                          return (
                            <div className="flex justify-between text-md font-inter-regular rounded bg-white w-full overflow-hidden focus:outline-none h-[72px] border border-border-gray mt-3">
                              <label
                                key={index}
                                className="inline-flex items-center py-2 text-sm select-none mr-4 gap-2 px-2">
                                <input
                                  autoComplete="off"
                                  type="radio"
                                  className="form-checkbox"
                                  checked={formattedAddress == address}
                                  onClick={e => {
                                    const completeAddress = parsedAddress(adress?.attributes);

                                    store.userStore?.setAddressID(adress.id);
                                    store.userStore?.setAddressLocationID(adress?.locationId);
                                    store?.userStore?.setAddress(formattedAddress, completeAddress);

                                    const account = activeEmployees?.find(
                                      emp => emp.id == foundAddress?.locationId
                                    );
                                    const isInvoiceEnabled =
                                      account?.attributes.invoice_payments == 'enabled';

                                    if (isInvoiceEnabled)
                                      store.userStore.setInvoiceDetails({
                                        invoice_payments: account?.attributes?.invoice_payments,
                                        account_billing_address:
                                          account?.attributes?.account_billing_address,
                                        account_billing_emails:
                                          account?.attributes?.account_billing_emails,
                                        account_billing_name:
                                          account?.attributes?.account_billing_name,
                                      });
                                    else
                                      store.userStore.setInvoiceDetails({
                                        invoice_payments: '',
                                        account_billing_address: '',
                                        account_billing_emails: '',
                                        account_billing_name: '',
                                      });
                                    setEnableContinue(false);
                                  }}
                                />
                                <span className="text-base text-slate-700 leading-7 font-inter-medium">
                                  {formattedAddress}
                                </span>
                              </label>
                            </div>
                          );
                        })}

                      {error && (
                        <span className="mt-5 text-red-600 text-md font-inter-medium">{error}</span>
                      )}

                      <div className="hidden sm:flex flex-col">{submitButtons}</div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>

          <Transition.Child
            as={'div'}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="sm:hidden">
              <div className="fixed bg-white w-full p-5 bottom-0 z-[90] justify-center items-center mt-10 border-t-2 border-gray-200">
                {submitButtons}
              </div>
            </div>
          </Transition.Child>
        </form>
      </Dialog>
    </Transition>
  );
}

export default observer(DuplicateAddressConfirmation);
