import classNames from 'classnames';
import { toJS } from 'mobx';
import { useRouter } from 'next/router';
import { StoreContext } from 'pages/_app';
import { useContext } from 'react';
import { isPresent } from 'utils/helpers/array';

function PlaceOrderButton() {
  const store = useContext(StoreContext);
  const groupOrderStore = toJS(store?.groupOrderStore);
  const router = useRouter();
  const isAdmin = router?.query?.admin;
  let { cart, orderDetails, guestUnCheckedOutOrderId } = groupOrderStore;

  const isAbovelimit = () => {
    return parseFloat(cart?.data?.subtotal) > parseFloat(orderDetails?.attributes?.subsidy);
  };

  const onClickPlaceOrder = () => {
    if (isAdmin) {
      let payload = {
        uncheckedout_order: {
          status: 'placed',
        },
      };

      const params = {
        slug: orderDetails?.attributes?.invitation_link,
      };

      store.userStore.setLoader(true);
      store.groupOrderStore
        .updateUncheckedOutOrder(guestUnCheckedOutOrderId, payload, params)
        .then(response => {
          store.userStore.setLoader(false);

          if (response?.data?.id) {
            window.location.href = `${process?.env?.NEXT_PUBLIC_APP_ADMIN_PANEL_LINK}/group-order/admin/orders/${orderDetails?.id}/${orderDetails?.attributes?.location_id}`;
          }
        });
    } else
      router.push({
        pathname: '/group-order/user-info',
      });
  };

  return (
    <>
      {isPresent(cart?.items) && (
        <button
          onClick={onClickPlaceOrder}
          className={classNames({
            'btn-purple mt-3 py-3 h-12 flex w-full items-center justify-center': true,
            'bg-accent hover:!bg-accent': isAbovelimit() && !isAdmin,
          })}
          disabled={!isAdmin ? isAbovelimit() : false}>
          {/* text-secondary-gray if disabled */}

          <span className="text-sm font-inter-medium">Place your order</span>
        </button>
      )}
    </>
  );
}

export default PlaceOrderButton;
