import Image from 'next/image';
import { ArrowDown, BusinessIcon, UserIcon } from 'assets/img';

const LoggedInAvatar = ({ onClick, currentUser, isAdmin }) => (
  <button onClick={onClick} className="flex items-center justify-center inline-flex gap-6">
    <div className="inline-flex gap-2">
      <Image src={isAdmin ? BusinessIcon : UserIcon} alt="business-icon" />

      <span className="text-slate-700 font-inter-medium text-base leading-tight capitalize">
        {currentUser?.attributes?.first_name} {currentUser?.attributes?.last_name}
      </span>

      {isAdmin && (
        <span className="text-slate-700 font-inter-medium text-base leading-tight">(admin)</span>
      )}
    </div>

    <Image src={ArrowDown} alt="arrow-down" className="pl-4" />
  </button>
);

export default LoggedInAvatar;
