import { makeAutoObservable, action } from 'mobx';
import api from '../client/api';
import qs from 'qs';
import { makePersistable } from 'mobx-persist-store';
import { client } from '../client';
import { nextLocalStorage } from '../utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';

class GroupOrderStore {
  dates = [];

  userDates = [];

  times = [];

  currentGroupOrder = null;

  orderDetails = null;

  cart = {
    items: [],
    data: null,
  };

  attendeeId = null;

  orders = null;

  ordersPagy = null;

  cutlery = false;

  groupOrderCartMobile = false;

  guestUnCheckedOutOrder = null;
  guestUnCheckedOutOrderId = null;

  signedUpUser = null;
  constructor(rootStore) {
    this.userStore = rootStore.UserStore;

    makePersistable(this, {
      name: 'GroupOrderStore',
      properties: [
        'currentGroupOrder',
        'cart',
        'attendeeId',
        'orderDetails',
        'cutlery',
        'signedUpUser',
        'guestUnCheckedOutOrder',
        'guestUnCheckedOutOrderId',
      ],
      storage: nextLocalStorage(),
    });
    makeAutoObservable(this);
  }

  async setGroupOrderCartMobile(value) {
    this.groupOrderCartMobile = value;
  }
  async setGroupOrderDetails(orderDetails) {
    this.orderDetails = orderDetails;
  }

  async clearCurrentGroupOrder() {
    this.currentGroupOrder = null;
    this.orderDetails = null;
    this.attendeeId = null;
  }

  async clearAttendeeOrderDetails() {
    this.orderDetails = null;
    this.attendeeId = null;
  }
  async setCurrentGroupOrder(orderDetails) {
    this.currentGroupOrder = orderDetails;
  }

  async updateCutlery(checked) {
    this.cutlery = checked;
  }

  async clearCart() {
    this.cart.items = [];
  }

  async getDatesForGroupOrder(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.getGroupOrderDates()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          if (params.admin) this.dates = data?.dates;
          else this.userDates = data?.dates;

          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async getTimesForGroupOrder(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.getGroupOrderTimes()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.times = data?.timeslots;

          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async createGroupOrder(payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.groupOrders()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.currentGroupOrder = data?.data;
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async createPreAdminGroupOrder(payload) {
    return client()
      .post(`${api.preAdminGroupOrders()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.currentGroupOrder = data?.data;
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async updateGroupOrder(id, payload) {
    return client()
      .put(`${api.groupOrders()}/${id}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.currentGroupOrder = data?.data;
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async getGroupOrderDetails(slug) {
    return client()
      .get(`${api.groupOrders()}/${slug}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.currentGroupOrder = data?.data;

          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async createUser(payload) {
    return client()
      .post(`${api.createUser()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.signedUpUser = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async resendConfirmationEmail(payload) {
    return client()
      .post(`${api.confimrations()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async confirmUser(payload) {
    return client()
      .put(`${api.confimrations()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async createAttendee(payload) {
    return client()
      .post(`${api.attendees()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.attendeeId = data?.attendee_id;
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async updateAttendee(id, payload) {
    return client()
      .put(`${api.attendees()}/${id}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.attendeeId = null;
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async removeAttendee(id, slug) {
    return client()
      .delete(`${api.attendees()}/${id}?slug=${slug}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async addToGroupOrderCart(payload, uncheckedOutOrderId, invitationLink) {
    return client()
      .post(
        `${api.guestGroupOrderItems()}?uncheckedout_order_id=${uncheckedOutOrderId}&slug=${invitationLink}`,
        payload
      )
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async updateGroupOrderItem(id, payload, params) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.guestGroupOrderItems()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async getAllGroupOrderCartItems(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.guestGroupOrderItems()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.cart.data = data?.data;
          this.cart.items = data?.order_items;

          return data;
        }),
        action('fetchError', error => {
          if (error.response?.data?.errors == 'Object was not found') {
            this.resetCart();
          } else {
            this.userStore.setErrorAlert(true, alertHandler(error.response));
          }

          return error;
        })
      );
  }

  async resetCart() {
    this.cart.items = [];
    this.cart.data = null;

    this.attendeeId = null;
  }

  async removeGroupOrderCartItem(itemId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.guestGroupOrderItems()}/${itemId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async removeAllGroupOrderCartItems(params) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.guestGroupOrderItems()}/1?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.cart = { ...this.cart, items: [], data: null };

          return data;
        }),
        action('fetchError', error => {
          if (error.response?.data?.errors == 'No cart items found!') {
            this.resetCart();
          } else {
            this.userStore.setErrorAlert(true, alertHandler(error.response));
          }

          return error;
        })
      );
  }

  async getAllOrders(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.groupOrders()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.orders = data?.data;
          this.ordersPagy = data?.pagy;

          return data;
        }),
        action('fetchError', error => {
          return error?.response;
        })
      );
  }

  async createGroupOrderEmployee(payload) {
    return client()
      .post(`${api.groupOrderEmployees()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async createGroupOrderStripeSession(payload) {
    return client()
      .post(`${api.groupOrderStripeSession()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.cart.stripeSessionId = data.id;
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async cancelGroupOrder(id) {
    return client()
      .delete(`${api.groupOrders()}/${id}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async generatePDF(slug, pdfName) {
    this.userStore.setLoader(true);

    return client()
      .get(`${api.groupOrders()}/${slug}.pdf`, { responseType: 'blob' })
      .then(
        action('fetchSuccess', response => {
          let newBlob = new Blob([response.data], { type: 'application/pdf' });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement('a');
          link.href = url;
          link.download = `${pdfName}.pdf`;
          link.click();
          setTimeout(function () {
            window.URL.revokeObjectURL(url);
          }, 100);

          this.userStore.setLoader(false);
          this.userStore.setErrorAlert(true, {
            title: 'Invoice generated successfully',
          });
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  //project 7 - attendee adding items to the group order
  async createUncheckedOutOrder(slug, payload) {
    this.userStore.setLoader(true);

    return client()
      .post(`${api.guestUncheckedOutOrder()}?slug=${slug}`, payload)
      .then(
        action('fetchSuccess', response => {
          this.userStore.setLoader(false);
          this.guestUnCheckedOutOrder = response.data?.data;
          this.guestUnCheckedOutOrderId = response.data?.data.id;
          return response?.data?.data;
        }),
        action('fetchError', error => {
          this.userStore.setLoader(false);
          return error;
        })
      );
  }

  async createUncheckedOutOrderByAdmin(slug, payload) {
    this.userStore.setLoader(true);

    return client()
      .post(`${api.guestUncheckedOutOrder()}?slug=${slug}&admin=true`, payload)
      .then(
        action('fetchSuccess', response => {
          this.userStore.setLoader(false);
          this.guestUnCheckedOutOrder = response.data?.data;
          this.guestUnCheckedOutOrderId = response.data?.data.id;
          return response?.data?.data;
        }),
        action('fetchError', error => {
          this.userStore.setLoader(false);
          return error;
        })
      );
  }

  async updateUncheckedOutOrder(id, payload, params) {
    let paramsString = qs.stringify(params);
    this.userStore.setLoader(true);
    return client()
      .put(`${api.guestUncheckedOutOrder()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.guestUnCheckedOutOrderId = null;
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }
}

export default GroupOrderStore;
