import { observer } from 'mobx-react';
import { Tab } from '@headlessui/react';
import { classNames } from 'utils/helpers/array';

function DeliveryPickupTabs({ minWidth, tabClassName, isCart }) {
  return (
    <Tab.Group
      onChange={index => {
        console.log('Changed selected tab to:', index);
      }}>
      <Tab.List
        className={classNames(
          'flex space-x-1 w-full h-12 rounded bg-background p-1',
          isCart ? 'px-1' : 'px-3'
        )}>
        <Tab
          key={'delivery'}
          styles={{ minWidth: minWidth }}
          className={({ selected }) =>
            classNames(
              'rounded-xl shadow py-2.5 text-md font-inter-normal leading-5 text-slate-700 px-10',
              'ring-white outline-none focus:outline-none flex justify-center',
              selected ? 'bg-white shadow' : 'hover:text-primary-color',
              isCart ? 'w-full' : 'w-[107px]',
              tabClassName
            )
          }>
          Delivery
        </Tab>

        <Tab
          key={'Pick-up'}
          disabled
          styles={{ minWidth: minWidth }}
          className={({ selected }) =>
            classNames(
              'flex items-center justify-center rounded-lg text-sm whitespace-nowrap font-inter-medium leading-5 text-border-gray px-10 text-md',
              'ring-white outline-none focus:outline-none',
              selected ? 'bg-white shadow' : '',
              isCart ? 'w-full' : 'w-[107px]',

              tabClassName
            )
          }>
          <div className="flex flex-col">
            <span className="leading-tight text-base font-inter-normal text-stone-300">
              Pick-up
            </span>
            <span className="text-[10px] leading-3 mt-1 font-inter-medium text-stone-300">
              Coming soon
            </span>
          </div>
        </Tab>
      </Tab.List>
    </Tab.Group>
  );
}

export default observer(DeliveryPickupTabs);
