export const compareAddresses = (addressesArr = [], address) => {
  const { street_name, street_number, city, province, postal_code } = address;
  let duplicateAddressesFound = false;
  const duplicateAddresses = [];
  let isSingleAddress = false;

  if (Array.isArray(addressesArr)) {
    addressesArr?.forEach(addrObj => {
      const addr = addrObj?.attributes;

      if (
        addr?.street_name == street_name &&
        addr?.street_number == street_number &&
        addr?.city == city &&
        addr?.province == province &&
        addr?.postal_code == postal_code
      ) {
        duplicateAddressesFound = true;
        duplicateAddresses.push(addrObj);
      }
    });
  }

  if (duplicateAddresses?.length === 1) {
    isSingleAddress = true;
  }

  return {
    duplicateAddressesFound,
    duplicateAddresses,
    isSingleAddress,
  };
};

export const formattedAddres = address => {
  let addres = ` ${address?.nickname ?? ''} 
  ${address?.destination_code ? `(${address.destination_code})` : ''} 
  - ${address?.suite ? `${address.suite} -` : ''} ${address?.street_number ?? ''} ${
    address?.street_name
  }, ${address?.city}, ${address?.province ?? ''} ${address?.postal_code ?? ''}`;

  return Object.values(address ? address : {}).every(value => value === '') ? '-' : addres;
};

export const parsedAddress = address => {
  return {
    suite: address.suite,
    street_number: address.street_number,
    street_name: address.street_name,
    city: address.city,
    province: address.province,
    postal_code: address.postal_code,
    nickname: address.nickname,
    destination_code: address.destination_code,
    delivery_instructions: address.delivery_instructions,
  };
};
