import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { StoreContext } from 'pages/_app';
import React, { useContext, useEffect, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { debounce } from 'utils/helpers/restaurants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import InputError from 'components/InputError/InputError';
import Image from 'next/image';
import { MapIcon } from 'assets/img';
import classNames from 'classnames';

function AddressInput({
  checkout,
  internalAddress,
  postalCodeError,
  showPostalCodeError,
  setShowPostalCodeError,
  menusAvailable,
  setMenusAvailable,
}) {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  let { address, completeAddress } = userStore;

  const [suite, setSuite] = useState(completeAddress.suite ?? '');
  const [isProvinceCleared, clearProvince] = useState(false);
  const [editPostalCode, setEditPostalCode] = useState(false);
  const [streetName, setStreetName] = useState(completeAddress.street_name ?? '');
  const [streetNumber, setStreetNumber] = useState(completeAddress?.street_number ?? '');
  const [city, setCity] = useState(completeAddress.city ?? '');

  const [province, setProvince] = useState(completeAddress.province ?? '');
  const [postalCode, setPostalCode] = useState(completeAddress.postal_code ?? '');

  const router = useRouter();

  const [showBorder, setShowBorder] = useState(false);

  useEffect(() => {
    if (
      !completeAddress?.province &&
      !isProvinceCleared &&
      store.userStore?.isHydrated &&
      !router?.query?.shared
    ) {
      setProvince(null);
      setSuite('');
      setStreetName('');
      setStreetNumber('');
      setCity('');
      setPostalCode('');
      clearProvince(true);
    }
  }, [completeAddress?.province, router?.query?.shared]);

  useEffect(() => {
    if (store.userStore?.isHydrated && router?.isReady && router?.query?.shared) {
      setProvince(internalAddress?.province);
      setSuite(internalAddress?.suite);
      setStreetName(internalAddress?.streetName);
      setStreetNumber(internalAddress?.streetNumber);
      setCity(internalAddress?.city);
      setPostalCode(internalAddress?.postalCode);
    }
  }, [
    router?.query?.shared,
    store.userStore?.isHydrated,
    router?.isReady,
    internalAddress?.province,
    internalAddress?.suite,
    internalAddress?.streetNumber,
    internalAddress?.streetName,
    internalAddress?.city,
    internalAddress?.postalCode,
  ]);

  const getAddress = () => {
    return `${streetNumber ?? ''} ${streetName ?? ''} ${city ?? ''}, ${province ?? ''}, ${
      postalCode ?? ''
    }`;
  };

  const getAddressInfo = async () => {
    let cityValue = '';
    let provinceValue = '';
    store.userStore?.getAddressInfo(postalCode).then(response => {
      store?.userStore?.setLoader(false);
      if (response?.results) {
        response?.results[0]?.address_components?.forEach(addressComponent => {
          if (addressComponent?.types?.includes('locality')) {
            cityValue = addressComponent?.long_name;
          } else if (addressComponent?.types?.includes('administrative_area_level_1')) {
            provinceValue = addressComponent?.long_name;
          }
        });

        setCity(cityValue);
        setProvince(provinceValue);

        const addressValue = `${cityValue ?? ''}, ${provinceValue ?? ''}, ${postalCode ?? ''}`;

        store.userStore?.setAddress(suite ? `${suite} - ${addressValue}` : addressValue, {
          suite: suite,
          street_number: '',
          street_name: '',
          city: cityValue,
          province: provinceValue,
          postal_code: postalCode,
        });
      }
    });
  };

  const updateCompleteAddress = () => {
    store.userStore?.setAddress(suite ? `${suite} - ${getAddress()}` : getAddress(), {
      suite: suite,
      street_number: streetNumber,
      street_name: streetName,
      city: city,
      province: province,
      postal_code: postalCode,
    });
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.NEXT_PUBLIC_APP_GOOGLE_MAP_KEY,
    options: {
      componentRestrictions: { country: 'CA' },
      fields: ['address_components', 'geometry', 'formatted_address'],
      types: ['address'],
    },
    onPlaceSelected: place => {
      store.userStore?.setAddressOnly(place?.formatted_address);

      const _address = place?.address_components?.reduce(
        (seed, { long_name, short_name, types }) => {
          types.forEach(t => {
            if (t == 'administrative_area_level_1') {
              seed[t] = short_name; // Set short_name for province
            } else {
              seed[t] = long_name; // Set long_name for other types
            }
          });
          return seed;
        },
        {}
      );

      store.userStore?.setCompleteAddress({
        suite: _address?.subpremise,
        street_number: _address?.street_number,
        street_name: _address?.route,
        city: _address?.locality,
        province: _address?.administrative_area_level_1,
        postal_code: _address?.postal_code,
      });

      router.replace(
        {
          pathname: router?.pathname,
          query: {
            ...QueryString.parse(router?.query),
            address: place?.formatted_address,
          },
        },
        undefined,
        {
          shallow: true,
        }
      );
    },
  });

  const removeAddressInQuery = () => {
    let query = QueryString.parse(router?.query);
    delete query['address'];

    router.replace(
      { pathname: router?.pathname, query: { ...QueryString.parse(query) } },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const handleOnBlur = debounce(e => {
    if (e?.target?.value === '') {
      store.userStore?.setAddress(e?.target?.value);

      removeAddressInQuery();
      setMenusAvailable(true);
    }
  }, 1000);

  useEffect(() => {
    if (!menusAvailable) {
      setShowBorder(true);
      const timer = setTimeout(() => {
        setShowBorder(false);
        setMenusAvailable(true);
      }, 1000);

      // Cleanup the timer on unmount or when menusAvailable changes
      return () => clearTimeout(timer);
    }
  }, [menusAvailable]);

  return (
    <>
      {!checkout ? (
        <div
          className={classNames('flex w-full md:mr-2 h-12 bg-background pl-2 rounded', {
            'border border-zinc-200': showBorder,
          })}>
          <Image src={MapIcon} alt="map-icon" className="pl-4" />

          <input
            ref={ref}
            className="input-light font-inter-medium my-0 rounded text-md w-full border-0 bg-background pl-2 outline-none focus:outline-none"
            placeholder="Type your address"
            defaultValue={address}
            onBlur={handleOnBlur}
          />
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row mt-5">
            <div className="md:mr-3 w-full md:w-1/2">
              <span className="text-sm mb-1">Street Number</span>

              <input
                required
                name="streetNumberField"
                className="input-light-1 address-inputs my-0 rounded-lg text-sm w-full bg-background pl-4 outline-none focus:outline-none"
                value={streetNumber}
                onBlur={updateCompleteAddress}
                onChange={e => setStreetNumber(e?.target?.value)}
              />
            </div>

            <div className="mt-5 md:mt-0 w-full md:w-1/2">
              <span className="text-sm mb-1">Street Name</span>

              <input
                required
                name="streetNameField"
                className="input-light-1 address-inputs my-0 rounded-lg text-sm w-full bg-background pl-4 outline-none focus:outline-none"
                value={streetName}
                onChange={e => setStreetName(e?.target?.value)}
                onBlur={updateCompleteAddress}
              />
            </div>
          </div>

          <div className="flex flex-col mt-5">
            <span className="text-sm mb-1">Unit/Suite</span>

            <input
              name="suiteField"
              className="input-light-1 address-inputs my-0 rounded-lg text-sm w-full bg-background pl-4 outline-none focus:outline-none"
              value={suite}
              onBlur={updateCompleteAddress}
              onChange={e => setSuite(e?.target?.value)}
            />
          </div>

          <div className="flex flex-col md:flex-row mt-5">
            <div className="md:mr-3 w-full md:w-1/2">
              <span className="text-sm mb-1">City</span>

              <input
                required
                name="cityField"
                className="input-light-1 address-inputs my-0 rounded-lg text-sm w-full bg-background pl-4 outline-none focus:outline-none"
                value={city}
                disabled={true}
                onBlur={updateCompleteAddress}
                onChange={e => setCity(e?.target?.value)}
              />
            </div>

            <div className="flex items-center mt-5 md:mt-0 w-full md:w-1/2">
              <div className="w-full">
                <span className="text-sm mb-1">Postal Code</span>

                <input
                  required
                  name="postalCodeField"
                  disabled={!editPostalCode}
                  maxLength={7}
                  className="input-light-1 address-inputs my-0 rounded-lg text-sm w-full bg-background pl-4 outline-none focus:outline-none"
                  value={postalCode}
                  onChange={e => {
                    setPostalCode(e?.target?.value);
                    setShowPostalCodeError(postalCodeError);
                  }}
                />

                {postalCodeError && showPostalCodeError && (
                  <InputError error="Invalid postal code" />
                )}
              </div>

              <div className="mt-5">
                <button
                  type="button"
                  className="flex items-end w-4 h-4 ml-3"
                  onClick={() => {
                    setEditPostalCode(!editPostalCode);
                    if (!editPostalCode) {
                      setStreetName('');
                      setStreetNumber('');
                      setSuite('');
                    } else {
                      getAddressInfo();
                    }
                  }}>
                  <FontAwesomeIcon
                    style={{ width: 16, height: 16 }}
                    icon={!editPostalCode ? faEdit : faCheck}
                    color="black"
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-5">
            <span className="text-sm mb-1">Province</span>

            <input
              required
              name="provinceField"
              className="input-light-1 address-inputs my-0 rounded-lg text-sm w-full bg-background pl-4 outline-none focus:outline-none"
              value={province}
              disabled={true}
              onBlur={updateCompleteAddress}
              onChange={e => setProvince(e?.target?.value)}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default observer(AddressInput);
