import Cookies from 'js-cookie';

export const setCookies = (employeeID, currentUser) => {
  const NODE_ENV = process?.env?.NEXT_PUBLIC_APP_NODE_ENV || 'development';

  const cookieOptions = {
    secure: false,
    domain: NODE_ENV === 'development' ? 'localhost' : '.hungerhub.com',
    path: '/',
  };

  Cookies.set('employee-id', employeeID, cookieOptions);
  Cookies.set('email', currentUser?.attributes?.email, cookieOptions);
  Cookies.set('user-token', currentUser?.attributes?.access_token, cookieOptions);
  Cookies.set('source', 'catering', cookieOptions);
};
