import AuthModal from 'components/AuthModal';
import ErrorAlert from 'components/ErrorAlert';
import Footer from 'components/Footer';
import CheckoutLayout from 'components/CheckoutLayout';
import { Loader } from 'components/Shared';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { StoreContext } from 'pages/_app';
import { useContext, useEffect } from 'react';
import SimpleLayout from 'components/SimpleLayout/SimpleLayout';
import ForgotPasswordModal from 'components/ForgotPasswordModal';
import CartMobile from 'components/CartMobile';
import FreshChat from 'components/FreshChat/FreshChat';
import GroupOrderLayout from 'components/GroupOrder/GroupOrderLayout/GroupOrderLayout';
import GroupOrderUserLayout from 'components/GroupOrder/GroupOrderUserLayout/GroupOrderUserLayout';
import LogoLayout from 'components/GroupOrder/LogoLayout/LogoLayout';
import LoggedInLayout from 'components/LoggedInLayout';
import GroupOrderCheckoutLayout from 'components/GroupOrder/GroupOrderCheckoutLayout';
import GroupOrderCartMobile from 'components/CartMobile/GroupOrderCartMobile';
import { useRouter } from 'next/router';

function Layout(props) {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const restaurantStore = toJS(store?.restaurantStore);
  let { isLoading, loginModal, forgotModal, currentUser } = userStore;
  let { activeRestaurant } = restaurantStore;
  let {
    groupOrderLayout,
    checkoutLayout,
    groupOrderCheckoutLayout,
    logoLayout, //true
    loggedInLayout,
    groupOrderUserLayout,
    noHeaderLayout,
    noFooterLayout,
  } = props;
  const router = useRouter();
  const queryParamsAvailable = router?.query?.date && router?.query?.time && router?.query?.address;

  useEffect(() => {
    store.userStore?.getUserProfile();
  }, []);

  const mainContent = (
    <main style={{ marginBottom: noHeaderLayout && !queryParamsAvailable ? '' : '72px' }}>
      {props.children}

      <ErrorAlert />

      <AuthModal visible={loginModal} setModal={value => store.userStore.setLoginModal(value)} />

      <ForgotPasswordModal
        visible={forgotModal}
        setModal={value => store.userStore.setForgotModal(value)}
      />

      <CartMobile restaurantDetail={activeRestaurant} />

      <GroupOrderCartMobile restaurantDetail={activeRestaurant} />

      <div key={`${currentUser?.attributes?.email}-freshchat`}>
        <FreshChat />
      </div>
    </main>
  );

  return (
    <>
      {loggedInLayout ? (
        <>
          {isLoading && <Loader />}

          <LoggedInLayout mainContent={mainContent} />
        </>
      ) : (
        <div>
          {checkoutLayout ? (
            <CheckoutLayout />
          ) : groupOrderLayout ? (
            <GroupOrderLayout />
          ) : logoLayout ? (
            <LogoLayout />
          ) : groupOrderUserLayout ? (
            <GroupOrderUserLayout />
          ) : groupOrderCheckoutLayout ? (
            <GroupOrderCheckoutLayout />
          ) : noHeaderLayout && !queryParamsAvailable ? null : (
            <SimpleLayout />
          )}

          {isLoading && <Loader />}

          {mainContent}

          {!noFooterLayout && <Footer />}
        </div>
      )}
    </>
  );
}

export default observer(Layout);
