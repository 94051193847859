export default {
  markets: () => `api/v1/odc/markets`,
  locationMenus: () => `api/v2/odc/menus`, //api/v1/odc/menus/location_menus
  settings: () => `api/v2/odc/settings`,
  menus: () => `api/v2/odc/menus`,
  allMenus: () => `api/v1/menus`,
  menuItems: () => `api/v1/odc/menu_items`,
  cart: () => `api/v2/odc/cart_items`, //api/v1/odc/cart_items
  unCheckedOutOrders: () => `api/v2/odc/uncheckedout_orders`,
  deliveryFee: restaurantId => `api/v1/odc/settings/${restaurantId}/delivery_fee`,
  stripeSessions: () => `api/v2/odc/stripe_sessions`, //api/v1/odc/stripe_sessions
  employees: () => `api/v2/odc/employees`, //api/v1/odc/employees
  orders: () => `api/v2/odc/orders`, //api/v1/odc/orders
  addressValidation: () => `api/v2/odc/address_validations`,
  resetPassword: () => `api/v1/passwords`,
  passwords: () => `api/v2/odc/passwords/new`,
  updateEmail: () => `api/v2/odc/emails`,
  sessions: () => `api/v2/odc/sessions`,
  availableDates: () => `api/v2/odc/dates`,
  availableTimes: () => `api/v2/odc/times`,
  validateEmail: () => `api/v2/odc/passwords/validate`,
  timezones: () => `api/v2/odc/timezones`,
  orderRecieve: () => `api/v2/odc/order_receives/send_email`,
  profiles: () => `api/v2/odc/profiles`,
  previewMenu: id => `api/v2/odc/menus/${id}/preview`,
  getSlug: stripeSessionId => `api/v2/odc/orders/${stripeSessionId}/get_slug`,

  addresses: () => `api/v2/odc/addresses`,
  getAddresses: () => `api/v2/uncatering_admin/addresses`,

  ///Group Order//////////////////
  getGroupOrderDates: () => `api/v2/odc/grouped_orders/dates`,
  getGroupOrderTimes: () => `api/v2/odc/grouped_orders/times`,
  groupOrders: () => `api/v2/uncatering_admin/group_orders`,
  createUser: () => `api/v2/odc/grouped_orders/users`,
  confimrations: () => `api/v2/odc/grouped_orders/confirmations`,
  attendees: () => `api/v2/odc/grouped_orders/attendees`,
  groupOrderItems: () => `api/v2/odc/grouped_orders/group_order_items`,
  groupOrderEmployees: () => `api/v2/odc/grouped_orders/employees`,
  groupOrderStripeSession: () => `api/v2/odc/grouped_orders/stripe_sessions`,
  groupOrderDeadline: () => `api/v2/guest/group_deadlines`,
  guestGroupOrderDetails: () => `api/v2/guest/group_orders`,
  preAdminGroupOrders: () => `api/v2/guest/group_orders`,
  //location credit cards
  cards: () => `api/v2/uncatering_admin/credit_cards`,
  paymentIntent: () => `api/v2/uncatering_admin/payment_intents`,

  //project 7
  guestUncheckedOutOrder: () => `api/v2/guest/uncheckedout_orders`,
  guestGroupOrderItems: () => `api/v2/guest/cart_items`,
};
