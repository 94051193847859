export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const reducer = (state, action, initialState) => {
  switch (action.type) {
    case 'reset':
      return initialState ?? {};
  }
  return {
    ...state,
    [action.field]: action.value,
  };
};

export const groupBy = (x, f) => x?.reduce((a, b, i) => ((a[f(b, i, x)] ||= [])?.push(b), a), {});

export const isPresent = arr => {
  return Array.isArray(arr) && arr?.length > 0;
};

export const getFiltersQuery = dietaryQuery => {
  return Array.isArray(dietaryQuery) ? dietaryQuery : dietaryQuery ? [dietaryQuery] : [];
};

export const hasMinimumTwo = arr => {
  return Array.isArray(arr) && arr?.length > 2;
};

export const hasOneElement = arr => {
  return Array.isArray(arr) && arr?.length === 1;
};

export const hasMinimumOne = arr => {
  return Array.isArray(arr) && arr?.length > 1;
};
export const hasOneItem = arr => {
  return Array.isArray(arr) && arr?.length === 1;
};
