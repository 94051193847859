import { makeAutoObservable, action } from 'mobx';
import api from '../client/api';
import qs from 'qs';
import { makePersistable } from 'mobx-persist-store';
import { client } from '../client';
import { nextLocalStorage } from '../utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { isPresent } from 'utils/helpers/array';

class PaymentStore {
  stripeCreditCards = [];
  defaultPaymentMethodId = null;
  defaultSelectedCard = null;
  newCard = false;
  cardElement = null;

  constructor(rootStore) {
    this.userStore = rootStore.UserStore;

    makePersistable(this, {
      name: 'PaymentStore',
      properties: [],
      storage: nextLocalStorage(),
    });
    makeAutoObservable(this);
  }

  async getLocationCreditCards(params) {
    //stripe cards
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.cards()}/?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.stripeCreditCards = data;
          this.setDefaultPaymentMethod(data?.customer);

          if (data?.customer && data?.payment_methods && isPresent(data?.payment_methods?.data)) {
            this.setDefaulSelectedCard(data?.customer, data?.payment_methods);
          }
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  setDefaultPaymentMethod(customer) {
    this.defaultPaymentMethod = customer?.invoice_settings?.default_payment_method;
  }

  setDefaulSelectedCard(customer, paymentMethods) {
    const defaultPaymentId = customer?.invoice_settings?.default_payment_method;
    this.defaultPaymentMethodId = defaultPaymentId;
    let card = paymentMethods?.data?.find(card => card?.id == defaultPaymentId);

    this.defaultPaymentMethodId = defaultPaymentId;
    this.defaultSelectedCard = {
      expMonth: card?.card?.exp_month,
      expYear: card?.card?.exp_year,
      brand: card?.card?.display_brand,
      last4: card?.card?.last4,
      customer_id: card?.customer,
      id: card?.id,
    };
  }

  resetDefaultPaymentMethodAndCard() {
    this.defaultSelectedCard = null;
    this.defaultPaymentMethodId = null;
  }

  async updateDefaultLocationCreditCards(paymentID, params) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.cards()}/${paymentID}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getLocationCreditCards({ location_id: params?.location_id });

          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async createLocationCreditCard(params) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.cards()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getLocationCreditCards({ location_id: params?.location_id });

          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async createLocationPaymentIntent(params) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.paymentIntent()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.userStore.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  saveCardElements(cardElement) {
    this.newCard = true;
    this.cardElement = cardElement;
  }

  setIsNewCard(newCard) {
    this.newCard = newCard;
  }
}

export default PaymentStore;
