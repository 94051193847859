import { makeAutoObservable, action } from 'mobx';
import { makePersistable, clearPersistedStore, isHydrated } from 'mobx-persist-store';
import { client } from '../client';
import api from '../client/api';
import qs from 'qs';
import { toJS } from 'mobx';
import { nextLocalStorage } from '../utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import moment from 'moment-timezone';
import dateTime from 'utils/helpers/dateTime';
import axios from 'axios';

const initialAddressState = {
  suite: '',
  street_number: '',
  street_name: '',
  city: '',
  province: '',
  postal_code: '',
  destination_code: '',
};
const initialRestaurantDirectoryData = {
  dietaryChosenTags: [],
  cuisineChosenTags: [],
  packagingChosenTags: [],
};
class UserStore {
  checkedOutOrderSlug = '';
  shipmentID = '';

  currentUser = null;
  isLoading = false;

  errorMessage = '';
  errorAlert = false;
  defaultRegion = {};

  allMenus = [];
  allMenusPagy = null;
  address = null;
  completeAddress = initialAddressState;
  prevValidAddress = '';
  prevValidCompleteAddress = '';
  selectedDate = null;
  selectedTime = '12:00 pm';

  stripeSessionId = null;
  comment = '';

  loginModal = false;
  forgotModal = false;
  restaurantDirectoryData = initialRestaurantDirectoryData;
  allFilters = null;

  orders = [];

  ordersPagy = null;

  sortBy = { label: 'Sort by Distance', value: '' };

  availableDates = [];
  allTimes = [];

  deliveryPickupModalGlobal = { state: false, fromCart: false };

  addressTimezone = null;

  timeOptionsLoading = false;

  mobileDateTimeModal = false;
  startGroupOrderShown = false;

  isAdmin = false;
  locationId = '';
  locationAddresses = [];
  adminLocationIds = []; //ids to fetch the stored addresses for all of the locations where role = admin
  isUncateringUser = false;
  employeeID = '';
  userLogin = false;
  addressID = '';
  invoiceDetails = [];

  uncateringEmpID = '';
  addressLocationID = '';
  accountPhone = '';
  activeEmployees = [];

  constructor() {
    makePersistable(this, {
      name: 'UserStore',
      properties: [
        'address',
        'allFilters',
        'currentUser',
        'defaultRegion',
        'completeAddress',
        'prevValidAddress',
        'prevValidCompleteAddress',
        'selectedDate',
        'selectedTime',
        'availableDates',
        'allTimes',
        'selectedTime',
        'addressTimezone',
        'startGroupOrderShown',
        'checkedOutOrderSlug',
        'shipmentID',
        'employeeID',
        'isAdmin',
        'isUncateringUser',
        'locationAddresses',
        'locationId',
        'userLogin',
        'addressID',
        'invoiceDetails',
        'uncateringEmpID',
        'addressLocationID',
        'adminLocationIds',
        'accountPhone',
        'activeEmployees',
        'deliveryPickupModalGlobal',
      ],
      storage: nextLocalStorage(),
    });
    makeAutoObservable(this);
  }

  get isHydrated() {
    return isHydrated(this);
  }

  async setErrorAlert(visible, errorMessage) {
    this.errorAlert = visible;

    if (errorMessage)
      if (!visible)
        setTimeout(() => {
          this.errorMessage = errorMessage;
        }, 500);
      else this.errorMessage = errorMessage;
  }

  async setLoader(value) {
    this.isLoading = value;
  }

  async setAddress(value, completeAddress) {
    this.address = value;

    this.completeAddress = completeAddress ?? initialAddressState;
  }

  async setAddressOnly(value) {
    this.address = value;
  }

  async setPrevAddress(value, completeAddress) {
    this.prevValidAddress = value;

    this.prevValidCompleteAddress = completeAddress ?? initialAddressState;
  }

  async setCompleteAddress(completeAddress) {
    this.completeAddress = completeAddress ?? initialAddressState;
  }

  async setDate(value) {
    this.selectedDate = value;
  }

  async setTime(value) {
    this.selectedTime = value;
  }

  async resetRestaurantDirectoryData() {
    this.restaurantDirectoryData = initialRestaurantDirectoryData;
  }

  async setSort(value) {
    this.sortBy = value;
  }

  async setLoginModal(value) {
    this.loginModal = value;
  }

  async setForgotModal(value) {
    this.forgotModal = value;
  }

  async setMobileDateTimeModal(value) {
    this.mobileDateTimeModal = value;
  }

  async setDeliveryPickupModal(value) {
    this.deliveryPickupModalGlobal = value;
  }

  async setStartOrderShown(value) {
    this.startGroupOrderShown = value;
  }

  async setCurrentUser(value) {
    this.currentUser = value;
  }

  async clearUser(isRestaurantDetailsPage = false) {
    this.currentUser = null;
    this.locationAddresses = [];
    this.addressID = '';
    this.adminLocationIds = [];

    if (isRestaurantDetailsPage) {
      this.address = '';
      this.selectedDate = null;
      this.selectedTime = null;
      this.completeAddress = initialAddressState;
    }
  }

  async getLocationMenus(params, signal) {
    let paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

    return client()
      .get(`${api.locationMenus()}?${paramsString}`, { signal })
      .then(
        action('fetchSuccess', ({ data }) => {
          if (params?.page == 1) this.allMenus = data?.data;
          else this.allMenus = this.allMenus.concat(data?.data);

          this.allMenusPagy = data?.pagy;

          return data;
        }),
        action('fetchError', error => {
          if (error?.response) this.setErrorAlert(true, alertHandler(error?.response));
          return error;
        })
      );
  }

  async getSettings() {
    return client()
      .get(`${api.settings()}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.allFilters = data?.data;

          return data?.data;
        }),
        action('fetchError', error => {
          this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async resetUserPassword(payload) {
    return client()
      .post(`${api.resetPassword()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async resendEmail(orderId) {
    return client()
      .post(`${api.orderRecieve()}?slug=${orderId}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async forgotPassword(email) {
    return client()
      .get(`${api.passwords()}?email=${encodeURIComponent(email)}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async updateEmail(payload) {
    return client()
      .patch(`${api.updateEmail()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async loginUser(payload) {
    return client()
      .post(`${api.sessions()}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          if (data?.data) this.currentUser = data?.data;
          return data;
        }),
        action('fetchError', error => {
          return error?.response;
        })
      );
  }

  async getUserOrders(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.orders()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.orders = data?.data;
          this.ordersPagy = data?.pagy;

          return data;
        }),
        action('fetchError', error => {
          return error?.response;
        })
      );
  }

  async generatePDF(orderSlug) {
    this.setLoader(true);

    return client()
      .get(`${api.orders()}/${orderSlug}.pdf`, { responseType: 'blob' })
      .then(
        action('fetchSuccess', response => {
          let newBlob = new Blob([response.data], { type: 'application/pdf' });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement('a');
          link.href = url;
          link.download = `${orderSlug}.pdf`;
          link.click();
          setTimeout(function () {
            window.URL.revokeObjectURL(url);
          }, 100);

          this.setLoader(false);
          this.setErrorAlert(true, {
            title: 'Invoice generated successfully',
          });
        }),
        action('fetchError', error => {
          this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async getAvailableDates(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.availableDates()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.availableDates = data?.dates;
          return data;
        }),
        action('fetchError', error => {
          return error?.response;
        })
      );
  }

  async getAvailableTimes(params) {
    let paramsString = qs.stringify(params);
    this.timeOptionsLoading = true;
    return client()
      .get(`${api.availableTimes()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.timeOptionsLoading = false;
          this.allTimes = data?.times;

          return data;
        }),
        action('fetchError', error => {
          this.timeOptionsLoading = false;
          return error?.response;
        })
      );
  }

  async validateEmail(email) {
    return client()
      .get(`${api.validateEmail()}?email=${encodeURIComponent(email)}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async getAndSetTimezone(address) {
    const encodedAddress = encodeURIComponent(address);

    if (address) {
      this.setLoader(true);
      return client()
        .get(`${api.timezones()}?address=${encodedAddress}`)
        .then(
          action('fetchSuccess', ({ data }) => {
            this.setLoader(false);
            if (data?.abbreviation !== dateTime.getLocalTimezone())
              this.addressTimezone = data?.abbreviation;
            else this.addressTimezone = '';

            return data;
          }),
          action('fetchError', error => {
            this.setLoader(false);
            this.setErrorAlert(true, alertHandler(error.response));
            return error;
          })
        );
    }
  }

  async getAddressInfo(address) {
    if (address)
      return axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.NEXT_PUBLIC_APP_GOOGLE_MAP_KEY}`
        )

        .then(
          action('fetchSuccess', ({ data }) => {
            return data;
          }),
          action('fetchError', error => {
            this.setErrorAlert(true, alertHandler(error.response));
            return error;
          })
        );
  }

  async getUserProfile() {
    if (this.currentUser)
      return client()
        .get(`${api.profiles()}/${this.currentUser.id}`)
        .then(
          action('fetchSuccess', ({ data }) => {
            this.currentUser = data?.data;

            return data;
          }),
          action('fetchError', error => {
            return error?.response;
          })
        );
  }

  setGuestUserDetails(slug, id) {
    this.checkedOutOrderSlug = slug;
    this.shipmentID = id;
  }

  async setAdminUser(value) {
    this.isAdmin = value;
  }

  async setUncateringUser(value) {
    this.isUncateringUser = value;
  }

  async setLocationID(value) {
    this.locationId = value;
  }

  async setEmployeeID(value) {
    this.employeeID = value;
  }

  async setLocationAddresses(value) {
    this.locationAddresses = value;
  }

  async getUserAddresses() {
    return client()
      .get(`${api.addresses()}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          return error?.response;
        })
      );
  }

  async getLocationAddresses(location_id) {
    return client()
      .get(`${api.getAddresses()}?location_id=${location_id}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          return error?.response;
        })
      );
  }

  async createLocationAddresses(location_id, payload) {
    return client()
      .post(`${api.getAddresses()}?location_id=${location_id}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          this.setErrorAlert(true, alertHandler(error.response));
          return error;
        })
      );
  }

  async setIsUserLogin(value) {
    this.userLogin = value;
  }

  async setAddressID(id) {
    this.addressID = id;
  }

  setInvoiceDetails(invoiceDetails) {
    this.invoiceDetails = invoiceDetails;
  }

  async createUserOrder(params) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.orders()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          return error?.response;
        })
      );
  }

  setUncateringEmployeeId(empID) {
    this.uncateringEmpID = empID;
  }

  async setAddressLocationID(id) {
    this.addressLocationID = id;
  }

  setAdminLocationIds(ids) {
    this.adminLocationIds = ids;
  }

  resetIDs() {
    this.isAdmin = false;
    this.addressID = '';
    this.addressLocationID = '';
    this.employeeID = '';
    this.isUncateringUser = false;
    this.locationId = '';
    this.uncateringEmpID = '';
    this.userLogin = false;
    this.accountPhone = '';
  }

  setLocationPhone(phone) {
    this.accountPhone = phone;
  }

  setActiveEmployees(activeEmployees) {
    this.activeEmployees = activeEmployees;
  }
}

export default UserStore;
