import Image from 'next/image';
import {
  FaqsIcon,
  GroupUsersIcon,
  Logo,
  LogoBlack,
  LogoHH,
  LogoutIcon,
  OrdersIcon,
} from 'assets/img';
import { StoreContext } from 'pages/_app';
import { useContext, Fragment, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Disclosure, Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import Screen from 'utils/helpers/ScreenSize';
import { useRouter } from 'next/router';
import ConfirmationModal from 'components/ConfirmationModal';
import classNames from 'classnames';
import LoggedInAvatar from 'components/Shared/LoggedInAvatar';
import { removeKey } from 'utils/helpers/localStorage';

function LoggedInLayout({ mainContent }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const [isDrawerOpen, setDrawer] = useState(false);
  const [logoutAlert, setLogoutAlert] = useState(null);
  const screenType = Screen.ScreenType();
  let { currentUser } = userStore;
  const router = useRouter();

  const logout = () => {
    store.userStore.setLoader(true);
    setDrawer(false);

    //remove auth-token from localStorage
    removeKey('auth_token');
    store.userStore?.setIsUserLogin(false);
    store.userStore?.resetIDs();

    setTimeout(() => {
      setTimeout(() => {
        store.userStore.setLoader(false);
        setLogoutAlert(null);
        router.push('/');
        store.userStore.clearUser();
        store.cartStore.resetCart();
      }, 1000);
    }, 1000);
  };

  let drawerContent = (
    <div className="flex w-full flex-col h-full justify-between overflow-y-scroll">
      <div className="flex px-2 flex-col mt-[38.5px]">
        <Link href="/orders">
          <button
            onClick={() => setDrawer(false)}
            className={classNames({
              'flex p-4 rounded-md items-center btn-style': true,
              'bg-white': router?.pathname?.includes('/orders'),
            })}>
            <Image src={OrdersIcon} className="icon" />
            <span className="text-md font-inter-medium ml-2">Orders</span>
          </button>
        </Link>

        <Link href="/group-order/admin/group-order-new">
          <button
            onClick={() => setDrawer(false)}
            className={classNames({
              'flex p-4 rounded-md items-center btn-style mt-2': true,
              'bg-white': router?.pathname == '/group-order/admin/group-order-new',
            })}>
            <Image src={GroupUsersIcon} className="icon" />
            <span className="text-md font-inter-medium ml-2">Group Order</span>

            <div className="flex bg-hh-accent-light items-center justify-center rounded-[6px] px-3 h-6 py-[6px] ml-[25px]">
              <span className="font-inter-medium text-xs text-white">New</span>
            </div>
          </button>
        </Link>

        <Link href="/faqs">
          <button
            onClick={() => setDrawer(false)}
            className={classNames({
              'flex p-4 rounded-md items-center btn-style mt-2': true,
              'bg-white': router?.pathname === '/faqs',
            })}>
            <Image src={FaqsIcon} className="icon" />
            <span className="text-md font-inter-medium ml-2">FAQ</span>
          </button>
        </Link>

        <Link href="/">
          <button
            onClick={() => setDrawer(false)}
            className="btn-style cursor-pointer flex bg-primary-dark hover:bg-hh-accent-dark rounded-md p-4 m-4 mt-5 max-w-[200px]">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faAdd} size="1x" className="icon text-white" />

              <span className="text-white text-md font-inter-medium ml-3">Place new order</span>
            </div>
          </button>
        </Link>
      </div>

      <div className="flex flex-col p-4">
        <Link href={`${process.env.NEXT_PUBLIC_MARKETING_SITE_URL}/uncatering`}>
          <div className="flex flex-col cursor-pointer p-4 border border-hh-grey rounded-[4px]">
            <Image src={LogoHH} layout="fixed" />

            <span className="text-md2 font-inter-semibold mt-2">Ordering Often?</span>

            <span className="text-md mt-3">
              Learn more about Uncatering™ - a meal program that connects your employees with the
              best restaurants for daily or weekly meals
            </span>

            <FontAwesomeIcon icon={faArrowRight} className="icon mt-2 text-un-accent-pink" />
          </div>
        </Link>

        <button
          type="button"
          onClick={() => setLogoutAlert(true)}
          className={'flex p-4 btn-style mt-6 rounded-md items-center'}>
          <Image src={LogoutIcon} className="icon" />
          <span className="text-md font-inter-medium ml-2">Log out</span>
        </button>
      </div>

      <ConfirmationModal
        visible={logoutAlert}
        setModal={setLogoutAlert}
        title={'Are you sure you want to logout?'}
        body={''}
        rightButtonText={'Logout'}
        leftButtonText={'Cancel'}
        onClickLeftButton={() => setLogoutAlert(null)}
        onClickRightButton={logout}
      />
    </div>
  );

  return (
    <>
      <Disclosure as="nav" className="mx-auto">
        <div className="flex min-h-screen">
          {/* Sidebar */}
          <aside className="flex-col w-[18%] bg-light-grey hidden md:flex md:fixed left-0 top-0 h-screen z-[50]">
            {/* Sidebar content */}

            <div className="flex w-full p-4 pl-8 border-b border-hh-grey" style={{ height: 82 }}>
              <Image
                src={LogoBlack}
                alt="Logo-hungerhub"
                layout="fixed"
                width={'152px'}
                height={'56px'}
              />
            </div>

            {drawerContent}
          </aside>

          {/* Main content */}
          <div className="flex-1 flex flex-col md:pl-[18%]">
            <header className="bg-white px-8 border-b border-hh-grey sticky top-0 z-50">
              <div
                className="flex items-center justify-between md:justify-end mx-auto"
                style={{ maxWidth: '1440px' }}>
                <div className="flex md:hidden items-center">
                  <Image
                    src={Logo}
                    alt="Logo-hungerhub"
                    layout="fixed"
                    width={'106px'}
                    height={'39px'}
                  />
                </div>

                <div className="flex relative items-center py-3 md:py-4">
                  {currentUser?.attributes ? (
                    <LoggedInAvatar
                      onClick={() => {
                        if (!screenType?.isDesktop) setDrawer(true);
                      }}
                      currentUser={currentUser}
                    />
                  ) : (
                    <button
                      onClick={() => store.userStore.setLoginModal(true)}
                      className={`btn-style outline-none focus:outline-none cursor-pointer py-1 bg-white rounded-sm flex items-center`}>
                      <span
                        className={`btn-style text-md text-primary-dark font-inter-semibold mx-0 hover:text-pink-purple`}>
                        LOG IN
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </header>

            {/* Page content */}
            {mainContent}
          </div>
        </div>

        <Transition appear show={isDrawerOpen} as={Fragment}>
          <Dialog as="div" unmount={false} onClose={() => {}} className="relative z-[60] md:hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-out duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 sm:inset-auto sm:bottom-0 sm:top-0 sm:right-0 flex w-full sm:w-1/2 justify-end h-screen">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-out-in duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <div className="flex w-full justify-end h-screen">
                  <Dialog.Overlay className="z-[60] fixed inset-0 bg-black bg-opacity-30" />

                  <div
                    className={`z-[60] flex flex-col h-full justify-between bg-white w-full px-2 pb-6 text-left shadow-xl`}>
                    <Dialog.Panel className="flex h-full overflow-scroll w-full">
                      <div
                        style={{ width: '100%' }}
                        className="absolute flex flex-col z-[60] top-0 left-0 pt-2 float-right bg-white">
                        <div className="">
                          <button
                            type="button"
                            onClick={() => setDrawer(!isDrawerOpen)}
                            className="flex btn-style bg-white justify-end p-3 mx-5 float-right">
                            <FontAwesomeIcon
                              style={{ width: 16, height: 26 }}
                              className="ml-2"
                              icon={faXmark}
                              color={'#DEE2E6'}
                              size="2x"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="flex flex-col justify-between bg-white mt-5">
                        <div className="flex flex-col h-full">{drawerContent}</div>
                      </div>
                    </Dialog.Panel>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </Disclosure>
    </>
  );
}

export default observer(LoggedInLayout);
