import React, { Fragment, useContext, useEffect, useReducer, useState } from 'react';
import Screen from 'utils/helpers/ScreenSize';
import { Dialog, Transition } from '@headlessui/react';
import { StoreContext } from 'pages/_app';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { MapIcon } from 'assets/img';
import classNames from 'classnames';
import { formattedAddres, parsedAddress } from 'utils/helpers/addresses';

function SingleAddressConfirmation({ visible, setModal }) {
  const screenType = Screen.ScreenType();
  const store = useContext(StoreContext);
  const cartStore = toJS(store?.cartStore);
  const userStore = toJS(store?.userStore);

  let {
    address,
    currentUser,
    completeAddress,
    adminLocationIds,
    locationId,
    accountPhone,
    activeEmployees,
  } = userStore;

  const initialState = {
    suite: '',
    nickname: '',
    error: '',
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const [enableContinue, setEnableContinue] = useState(true);

  let { error } = provider;

  useEffect(() => {
    dispatch({ field: 'suite', value: '' });
    dispatch({ field: 'nickname', value: '' });
    dispatch({ field: 'error', value: '' });
  }, [visible]);

  const onContinuePress = e => {
    e.preventDefault();

    const payload = {
      address: {
        suite: provider?.suite,
        street_number: completeAddress?.street_number,
        street_name: completeAddress?.street_name,
        city: completeAddress?.city,
        province: completeAddress?.province,
        postal_code: completeAddress?.postal_code,
        nickname: provider?.nickname,
        delivery_contact_phone: accountPhone,
        delivery_contact_name:
          `${currentUser.attributes.first_name.trim()} ${currentUser.attributes.last_name.trim()}`.trim(),
        delivery_contact_email: currentUser.attributes.email,
        delivery_instructions: '',
      },
    };

    store.userStore?.setLoader(true);
    store.userStore
      .createLocationAddresses(locationId, payload)
      .then(response => {
        store.userStore?.setLoader(false);

        if (response?.data?.errors) dispatch({ field: 'error', value: response?.data?.errors });

        if (response?.data) {
          setModal(false);

          let _address = response?.data?.attributes;

          store.userStore?.setAddress(_address?.complete_address, parsedAddress(_address));

          getAllLocationAddresses(adminLocationIds);
        }
      })
      .catch(err => {
        console.log('err', err);
        store.userStore?.setLoader(false);
      });
  };

  const setCurrentAddress = addresses => {
    if (Array.isArray(addresses)) {
      addresses?.forEach(addrObj => {
        const addr = addrObj?.attributes;

        if (
          addr?.street_name == completeAddress.street_name &&
          addr?.street_number == completeAddress.street_number &&
          addr?.city == completeAddress.city &&
          addr?.province == completeAddress.province &&
          addr?.postal_code == completeAddress.postal_code &&
          addr?.suite == provider.suite
        ) {
          let formattedAddress = formattedAddres(addr);

          store.userStore?.setAddressID(addrObj.id);
          store.userStore?.setAddressLocationID(addrObj?.locationId);
          store.userStore?.setAddress(formattedAddress, parsedAddress(addr));

          const account = activeEmployees?.find(emp => emp.id == foundAddress?.locationId);
          const isInvoiceEnabled = account?.attributes.invoice_payments == 'enabled';

          if (isInvoiceEnabled)
            store.userStore.setInvoiceDetails({
              invoice_payments: account?.attributes?.invoice_payments,
              account_billing_address: account?.attributes?.account_billing_address,
              account_billing_emails: account?.attributes?.account_billing_emails,
              account_billing_name: account?.attributes?.account_billing_name,
            });
          else
            store.userStore.setInvoiceDetails({
              invoice_payments: '',
              account_billing_address: '',
              account_billing_emails: '',
              account_billing_name: '',
            });
        }
      });
    }
  };

  const getAllLocationAddresses = async adminLocationIds => {
    if (adminLocationIds?.length > 0) {
      let allAddresses = [];

      try {
        await Promise.all(
          adminLocationIds?.map(async locationId => {
            try {
              const response = await store.userStore.getLocationAddresses(locationId);
              const addresses = response?.data;

              if (addresses) {
                const addressesWithLocationId = addresses.map(address => ({
                  ...address,
                  locationId,
                }));
                allAddresses.push(...addressesWithLocationId);
              }
            } catch (error) {
              console.log('err', error);
              store.userStore?.setLoader(false);
            }
          })
        );

        setCurrentAddress(allAddresses);
        store.userStore.setLocationAddresses(allAddresses);
        setDuplicateAddress(allAddresses);

        store.userStore?.setLoader(false);
      } catch (error) {
        console.log('err', error);
        store.userStore?.setLoader(false);
      }
    }
  };

  const submitButtons = (
    <div className="flex flex-col">
      <button
        type="submit"
        className={classNames({
          'btn-purple mt-5 h-12': true,
          'bg-gray-400': enableContinue,
          'hover:bg-gray-400': enableContinue,
        })}
        disabled={enableContinue}>
        Continue
      </button>
    </div>
  );

  useEffect(() => {
    setEnableContinue(provider.nickname == '');
  }, [provider.nickname]);

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <form onSubmit={onContinuePress}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed top-0 right-0 left-0 sm:inset-0 overflow-y-auto">
            <div className="flex min-h-screen sm:min-h-full sm:items-center sm:justify-center sm:p-4 md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="flex flex-col flex-1 md:flex-initial w-4/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white py-10 px-8 text-left align-middle shadow-xl transition-all">
                  <div className="flex w-full justify-center items-center">
                    <Dialog.Title
                      as="h3"
                      style={{
                        fontSize: screenType?.isTabletOrMobile ? 23 : 32,
                      }}
                      className="font-inter-semibold leading-6 text-black-light">
                      <span className="text-[24px]">
                        Please complete the following details for your delivery address.
                      </span>
                    </Dialog.Title>
                  </div>

                  <div className="flex flex-col justify-center items-center flex-wrap mt-10 md:w-full">
                    <div className="flex flex-col w-full">
                      <div className="flex inline-flex input-gray-bg-border bg-zinc-100 rounded gap-2">
                        <Image src={MapIcon} alt="map-icon" />
                        <input
                          disabled
                          type={'address'}
                          className="w-full text-md text-slate-700 bg-transparent"
                          value={address}
                        />
                      </div>

                      <div className="flex justify-between text-md font-inter-regular rounded-xl bg-background w-full overflow-hidden focus:outline-none h-12 border border-border-gray mt-3">
                        <input
                          className="flex w-full bg-background focus:outline-none rounded-l-md px-3"
                          placeholder="Unit/suite (optional)"
                          value={provider.suite}
                          onChange={e => {
                            if (error) dispatch({ field: 'error', value: '' });

                            dispatch({
                              field: 'suite',
                              value: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="flex justify-between text-md font-inter-regular rounded-xl bg-background w-full overflow-hidden focus:outline-none h-12 border border-border-gray mt-3">
                        <input
                          required
                          className="flex w-full bg-background focus:outline-none rounded-l-md px-3"
                          placeholder="Address nickname"
                          value={provider.nickname}
                          onChange={e => {
                            if (error) dispatch({ field: 'error', value: '' });

                            dispatch({
                              field: 'nickname',
                              value: e.target.value,
                            });
                          }}
                        />
                      </div>

                      {error && (
                        <span className="mt-5 text-red-600 text-md font-inter-medium">{error}</span>
                      )}

                      <div className="hidden sm:flex flex-col">{submitButtons}</div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>

          <Transition.Child
            as={'div'}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="sm:hidden">
              <div className="fixed bg-white w-full p-5 bottom-0 z-[90] justify-center items-center mt-10 border-t-2 border-gray-200">
                {submitButtons}
              </div>
            </div>
          </Transition.Child>
        </form>
      </Dialog>
    </Transition>
  );
}

export default observer(SingleAddressConfirmation);
