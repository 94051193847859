import UserStore from './user';
import MarketManagementStore from './marketManagement';
import RestaurantStore from './restaurant';
import CartStore from './cart';
import GroupOrderStore from './groupOrder';
import PaymentStore from './payment';
import { create } from 'mobx-persist';

// const hydrate = create({
//   storage: localStorage,
//   jsonify: true
// });

class RootStore {
  UserStore = new UserStore(this);
  MarketManagementStore = new MarketManagementStore(this);
  RestaurantStore = new RestaurantStore(this);
  CartStore = new CartStore(this);

  GroupOrderStore = new GroupOrderStore(this);
  PaymentStore = new PaymentStore(this);

  constructor() {
    // hydrate('Store', this.UserStore);
    // hydrate('Store', this.RestaurantManagementStore);
  }
}

export default RootStore;
