import { ClockIcon } from 'assets/img';
import React from 'react';
import { components } from 'react-select';
import Image from 'next/image';

const ValueContainer = ({ children, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        <div className="flex inline-flex gap-2">
          <Image src={ClockIcon} alt="clock-icon" />

          {children}
        </div>
      </components.ValueContainer>
    )
  );
};

export default ValueContainer;
