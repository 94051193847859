import { StoreContext } from 'pages/_app';
import { useEffect, useContext, Fragment, useState } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import {
  CartBucket,
  FaqsIcon,
  Filter,
  GroupUsersIcon,
  LogoutIcon,
  OrdersIcon,
  SettingsIcon,
  UserIcon,
  BackgroundImage,
} from 'assets/img';
import Image from 'next/image';
import { Disclosure, Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faAngleLeft, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ConfirmationModal from 'components/ConfirmationModal';
import Logo from 'components/Logo';
import LoggedInAvatar from 'components/Shared/LoggedInAvatar';
import styles from './GroupOrderLayout.module.scss';
import { removeKey } from 'utils/helpers/localStorage';
// import { setCookies } from 'utils/cookies/cookieHandler';

function GroupOrderLayout() {
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const [isDrawerOpen, setDrawer] = useState(false);
  const [logoutAlert, setLogoutAlert] = useState(null);
  let { currentUser, isAdmin, isUncateringUser, employeeID } = userStore;
  const router = useRouter();
  const [isTopRightDrawerOpen, setTopRightDrawer] = useState(false);

  const moveBack = () => {
    store.userStore.setLoader(true);
    router.back();
  };

  const logout = () => {
    setTopRightDrawer(false);
    store.userStore.setLoader(true);

    setTimeout(() => {
      store.userStore.setLoader(false);
      setDrawer(false);

      setTimeout(() => {
        setLogoutAlert(null);
      }, 1000);

      const isRestaurantDetailsPage = router?.route == '/restaurant/[id]/[name]';

      store.userStore.clearUser(isRestaurantDetailsPage);

      store.cartStore.resetCart();

      //remove auth-token from localStorage
      removeKey('auth_token');
      store.userStore?.setIsUserLogin(false);
      store.userStore?.resetIDs();

      const isOrderDetailsPage = router?.route == '/order_confirmation/[slug]';

      if (isOrderDetailsPage) {
        router.push('/');
      } else if (isRestaurantDetailsPage) {
        router.reload();
      }
    }, 1000);
  };

  const onLoginPressMobile = () => {
    setDrawer(false);

    setTimeout(() => {
      store.userStore.setLoginModal(true);
    }, 1000);
  };

  const onPressLogout = isMobile => {
    if (isMobile) {
      setDrawer(false);

      setTimeout(() => {
        setLogoutAlert(true);
      }, 1000);
    } else {
      setLogoutAlert(true);
    }
  };

  const onClickCancel = () => {
    setLogoutAlert(null);
  };

  const handleAdminLogin = () => {
    // setCookies(employeeID, currentUser);
    // window.location.href = process?.env?.NEXT_PUBLIC_APP_ADMIN_PANEL_LINK;
  };

  const handleUserAccountLogin = () => {
    // setCookies(employeeID, currentUser);
    // window.location.href = process?.env?.NEXT_UNCATERING_APP_URL;
  };

  const backButton = (
    <button onClick={() => router.back()} className="flex btn-style items-center">
      <div className="flex" style={{ width: 16, height: 16 }}>
        <FontAwesomeIcon icon={faAngleLeft} size="1x" className="icon" />
      </div>

      <span className="text-md text-left text-black-light ml-3">Back to restaurant</span>
    </button>
  );

  return (
    <>
      <Disclosure
        as="nav"
        className="flex py-3 md:items-center md:justify-between bg-white px-8 border-b-2 border-background mx-auto sticky top-0 md:static z-50"
        style={{ maxWidth: '1440px' }}>
        {() => (
          <>
            <div className="hidden md:flex relative items-center justify-center w-full justify-between py-5 sm:py-0">
              {!router?.route?.includes('confirmation') &&
                !router?.query?.confirmation &&
                backButton}

              <div className="hidden sm:flex flex-shrink-0 items-center btn-style">
                <Logo />
              </div>

              <div className="flex items-center">
                <div className={styles.buttonsContainer}>
                  {currentUser?.attributes && (
                    <div className="flex">
                      <LoggedInAvatar
                        onClick={() => {
                          setTopRightDrawer(true);
                        }}
                        currentUser={currentUser}
                        isAdmin={isAdmin}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Transition appear show={isTopRightDrawerOpen} as={Fragment}>
              <Dialog
                as="div"
                unmount={false}
                onClose={() => {
                  setTopRightDrawer(false);
                }}
                className="relative z-[90]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div
                    onClick={() => setTopRightDrawer(false)}
                    className="fixed inset-0 bg-black bg-opacity-25"
                  />
                </Transition.Child>

                <div className="fixed sm:top-[65px] sm:right-[65px]">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-out-in duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full">
                    <div className="flex w-full">
                      <div className={`z-[60] flex flex-col bg-white rounded-xl w-full shadow-xl`}>
                        <Dialog.Panel className="overflow-scroll w-full">
                          <div className="flex flex-col justify-between bg-white rounded-xl">
                            <div className="flex flex-col items-start">
                              <a
                                className="px-8 pt-2 pb-2 hover:bg-slate-50 hover:rounded-t-xl text-md btn-style w-full cursor-pointer"
                                href={process?.env?.NEXT_PUBLIC_APP_ADMIN_PANEL_LINK}
                                onClick={handleAdminLogin}>
                                <Image src={SettingsIcon} alt="settings-icon" />
                                <span className="ml-4">Go to admin panel</span>
                              </a>

                              {isUncateringUser && (
                                <a
                                  className="px-8 pt-2 pb-2 hover:bg-slate-50 text-md btn-style w-full cursor-pointer"
                                  href={process?.env?.NEXT_PUBLIC_APP_UNCATERING_APP_URL}
                                  onClick={handleUserAccountLogin}>
                                  <Image src={UserIcon} alt="user-icon" />
                                  <span className="ml-4">Switch to user account</span>
                                </a>
                              )}

                              <a
                                className="px-8 pt-2 pb-2 hover:bg-slate-50 hover:rounded-b-xl text-md btn-style w-full cursor-pointer"
                                onClick={() => onPressLogout(true)}>
                                <Image src={LogoutIcon} alt="logout-icon" />
                                <span className="ml-4">Logout</span>
                              </a>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>

            <div className="hidden sm:flex md:hidden w-full items-center justify-between">
              <button className="flex items-center btn-style" onClick={moveBack}>
                <div className="flex" style={{ width: 16, height: 16 }}>
                  <FontAwesomeIcon icon={faAngleLeft} size="1x" className="icon" />
                </div>

                <span className="text-md text-left text-black-light ml-3">Back</span>
              </button>

              <div className="hidden sm:flex flex-shrink-0 items-center btn-style">
                <Logo />
              </div>

              <div className="flex">
                {/* <button
                  onClick={() => setDrawer(true)}
                  className="inline-flex btn-style items-center btn-style justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <FontAwesomeIcon
                    width={22}
                    height={22}
                    className="ml-2"
                    icon={faBars}
                    color={'#2D3958'}
                    size="2x"
                  />
                </button> */}

                <div className={styles.buttonsContainer}>
                  {currentUser?.attributes && (
                    <div className="flex">
                      <LoggedInAvatar
                        onClick={() => {
                          setTopRightDrawer(true);
                        }}
                        currentUser={currentUser}
                        isAdmin={isAdmin}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex sm:hidden w-full items-center justify-center">
              <div className="flex items-center btn-style">
                <Logo width={'106px'} height={'39px'} />
              </div>
            </div>

            <Transition appear show={isDrawerOpen} as={Fragment}>
              <Dialog
                as="div"
                unmount={false}
                onClose={() => {}}
                className="relative z-[60] md:hidden">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 sm:inset-auto sm:bottom-0 sm:top-0 sm:right-0 flex w-full sm:w-3/4 justify-end h-screen">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-out-in duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full">
                    <div className="flex w-full justify-end h-screen">
                      <Dialog.Overlay className="z-[60] fixed inset-0 bg-black bg-opacity-30" />

                      <div
                        className={`z-[60] flex flex-col justify-between bg-white w-full px-6 pb-6 text-left shadow-xl`}>
                        <Dialog.Panel className="overflow-scroll w-full">
                          <div
                            style={{ width: '100%' }}
                            className="absolute flex flex-col z-[60] top-0 left-0 pt-2 float-right bg-white">
                            <div className="">
                              <button
                                type="button"
                                onClick={() => setDrawer(!isDrawerOpen)}
                                className="flex btn-style bg-white justify-end p-3 mx-5 float-right">
                                <FontAwesomeIcon
                                  style={{ width: 16, height: 26 }}
                                  className="ml-2"
                                  icon={faXmark}
                                  color={'#DEE2E6'}
                                  size="2x"
                                />
                              </button>
                            </div>
                          </div>

                          <div className="flex flex-col justify-between bg-white mt-16">
                            <div className="flex flex-col m-4">
                              <Link href="/orders">
                                <span className="font-inter-medium text-md">ORDERS</span>
                              </Link>

                              <button onClick={() => setDrawer(!isDrawerOpen)}>
                                <div className="flex items-center mt-8">
                                  <span className="font-inter-medium text-md">GROUP ORDER</span>

                                  <div className="bg-primary-dark py-[6px] px-3 ml-7 rounded-md flex items-center justify-center">
                                    <span className="font-inter-medium text-white">NEW</span>
                                  </div>
                                </div>
                              </button>

                              <Link href="/">
                                <div className="mt-8">
                                  <span className="font-inter-medium text-md">FAQ</span>
                                </div>
                              </Link>

                              <button className="btn-style flex bg-primary-dark rounded-md p-4 mt-9">
                                <div className="flex items-center">
                                  <FontAwesomeIcon
                                    icon={faAdd}
                                    size="1x"
                                    className="icon text-white"
                                  />

                                  <span className="text-white text-md font-inter-medium ml-3">
                                    Place new order
                                  </span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </div>
                    </div>
                  </Transition.Child>
                </div>

                <div className="">
                  <Transition.Child
                    as={'div'}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="md:hidden z-[100]">
                      <div className="fixed bg-white w-full sm:w-3/4 sm:right-0 py-5 bottom-0 justify-center items-center mt-16">
                        <div className="flex flex-col justify-end px-5 w-full translate-x-0">
                          {!currentUser?.attributes ? (
                            <>
                              <button
                                onClick={onLoginPressMobile}
                                className="bg-primary-dark btn-style hover:bg-pink-purple px-4 text-white rounded-lg py-2 w-full">
                                <span className="mx-0 text-xs">LOG IN</span>
                              </button>
                            </>
                          ) : (
                            <div className="flex flex-col">
                              {currentUser?.attributes?.has_orders && (
                                <button
                                  onClick={() => {
                                    setDrawer(false);
                                    router?.push('/orders');
                                  }}
                                  className="tn-style px-4 text-primary-color font-inter-medium text-sm py-2 w-full mb-5">
                                  <span className="mx-0">ORDER HISTORY</span>
                                </button>
                              )}

                              <button
                                onClick={() => onPressLogout(true)}
                                className="bg-primary-dark btn-style hover:bg-pink-purple px-4 text-white rounded-lg py-2 w-full">
                                <span className="mx-0 text-xs">LOG OUT</span>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
          </>
        )}
      </Disclosure>

      {logoutAlert && (
        <ConfirmationModal
          visible={logoutAlert}
          setModal={setLogoutAlert}
          title={'Are you sure you want to logout?'}
          body={''}
          rightButtonText={'Logout'}
          leftButtonText={'Cancel'}
          onClickLeftButton={onClickCancel}
          onClickRightButton={logout}
        />
      )}
    </>
  );
}

export default observer(GroupOrderLayout);
